import {inject, LogManager} from "aurelia-framework";
import {ActionHandlerInterface} from "./action-handler.interface";
import {DialogService} from "aurelia-dialog";
import {ConfigurationLoader} from "../../form/loader/configuration-loader";
import EditorModal from "../../editor/editor-modal";
import {Client} from "../../api/client";

const logger = LogManager.getLogger('action-handler');
logger.setLevel(LogManager.logLevel.none); // Disable logging for this class by setting "LogManager.logLevel.none"

@inject(DialogService, ConfigurationLoader, Client)
export class EditorActionHandler extends ActionHandlerInterface {

    constructor(dialog, formConfig, client) {
        super();

        this.dialog = dialog;
        this.formConfig = formConfig;
        this.client = client;
    }

    getActionType = () => 'editor';

    getReturnContext = ({label, formId, ...a}, {id, modelId, contextObj}) =>
        ({label: contextObj?.title || label, formId, id, modelId});

    getAction = ({id, modelId, formId, label}) => async () => {
        logger.debug('Performing `editor` action with context ', {id, modelId, formId});

        const reference = {id, modelId};
        const data = await this.client.get(modelId + '/' + id);
        const config = await this.formConfig.get(formId, reference);
        const {property, label: formLabel, public: p, options: {context, blocks}} = config.fields[0];

        return this.dialog.open({
            viewModel: EditorModal,
            model: {
                label: label || formLabel,
                blocks,
                context,
                formId,
                id,
                modelId,
                property,
                public: p,
                formConfig: config,
                data: data,
                content: data[property],
                reference
            }
        });
    };

}
