import React, {useContext, useState} from "react";
import {Button} from "rsuite";
import {Container} from "aurelia-framework";
import {StandardActions} from "../standard-actions";
import SioIcon from "../../icon/rsuite-icon-font/SioIcon";
import AureliaContext from "../../utilities/aurelia-context";

/** @type {{getAction: (config: object, context: object) => {action: () => Promise<any>}}} */
const actions = Container.instance.get(StandardActions);

/**
 * @param {object} config
 * @param {ObjectRef|object} context
 * @param {"xs"|"sm"|"md"|"lg"} size
 * @param {boolean} loading
 * @param {string|JSX.Element} icon
 * @param {function }onSubmitted
 */
export const ActionButton = ({config, context, size = "sm", loading, icon, onSubmitted, style}) => {
    const {i18n} = useContext(AureliaContext)
    const [executing, setExecuting] = useState(false);

    const executeAction = async () => {
        try {
            setExecuting(true);

            const result = await actions.getAction(config, context).action();

            if (onSubmitted) {
                onSubmitted(result);
            }
        } finally {
            setExecuting(false);
        }
    };

    return (
        <Button size={size} onClick={executeAction} loading={loading || executing} className={config.buttonClass ?? "btn btn-default"} style={style}>
            {icon && <SioIcon size={size} icon={icon}/>}
            {"string" === typeof config.label ? i18n.tr(config.label) : config.label}
        </Button>
    );
};
