import { inject, LogManager } from "aurelia-framework";
import { Client } from "./client";
import { AuthTokenStorage } from "../auth/auth-token-storage";
import { EventAggregator } from "aurelia-event-aggregator";
import { LocaleService } from "../i18n/locale-service";
import * as Sentry from "@sentry/browser";
import getPropertyByPath from "../utilities/get-property-by-path";
import { EventSourcePolyfill } from "event-source-polyfill";
import { FlashService } from "../flash/flash-service";
import { AureliaConfiguration } from "aurelia-configuration";
import { Notification } from "../notification/notification";
const logger = LogManager.getLogger("user-client");

@inject(
    Client,
    AuthTokenStorage,
    AureliaConfiguration,
    LocaleService,
    EventAggregator,
    FlashService,
    Notification
)
export class UserClient {
    user;

    constructor(
        client,
        authTokenStorage,
        aureliaConfiguration,
        localeService,
        ea,
        flashService,
        notification
    ) {
        this.client = client;
        this.authTokenStorage = authTokenStorage;
        this.appConfig = aureliaConfiguration;
        this.localeService = localeService;
        this.logger = LogManager.getLogger("api");
        this.ea = ea;
        this.flashService = flashService;
        this.notification = notification;

        this.ea.subscribe("sio_login_change", (response) => {
            if (!response.loggedIn) {
                this.user = null;

                if (this.eventSource) {
                    this.eventSource.close();
                }

                if (this.pushNotificationSource) {
                    this.pushNotificationSource.close();
                }
            }
        });
    }

    processMessage(message) {
        if (message.type === "notification") {
            if (message.status == "success") {
                this.flashService.success(message.message);
            } else {
                this.flashService.error(message.message, null, {
                    closeButton: true,
                    tapToDismiss: false,
                    timeOut: 0,
                    extendedTimeOut: 0,
                });
            }
        }

        if (message.type === 'activity') {
            this.ea.publish('sio_activity', message);
        }

        if (message.modelId) {
            this.ea.publish('sio_form_post_submit', {config: {modelId: message.modelId}});
        }
    }

    processPushNotification(notification) {
        if (notification.type === "forward") {
            logger.debug("FORWARD TO:" + notification?.body);
            let params = `scrollbars=yes,resizable=yes,status=no,location=no,toolbar=no,menubar=no`;
            window.open(notification.body, "_blank", params);
        } else {
            this.ea.publish("sio_notification", notification);
        }
    }

    load() {
        if (!this.authTokenStorage.isLoggedIn()) {
            return;
        }

        return this.client
            .get("user/logged-in")
            .then((user) => {
                this.user = user;
                this.flashService.hideAutomatically = this.user?.settings?.userSettings?.hideErrorsAutomatically;
                let mercureUrl = this.appConfig.get("mercureUrl");

                if (this.user.mercureToken && mercureUrl) {
                    let lastEventId = "";

                    if (localStorage.getItem("lastEventId") !== null) {
                        lastEventId =
                            "?Last-Event-ID=" +
                            localStorage.getItem("lastEventId");
                    }

                    const url = new URL(
                        mercureUrl + "/.well-known/mercure" + lastEventId
                    );
                    //Subscribe to anything allowed by the token
                    //url.searchParams.append('topic', 'https://{domain}/{+anything}');
                    //Todo subscribing to anything does not work, because mercure sends updates even though permission is not allowed
                    url.searchParams.append(
                        "topic",
                        "https://{domain}/user/user/" + this.user.id
                    );

                    this.eventSource = new EventSourcePolyfill(url.toString(), {
                        headers: {
                            Authorization: "Bearer " + this.user.mercureToken,
                        },
                        lastEventIdQueryParameterName: "Last-Event-ID",
                        heartbeatTimeout: 1800000,
                    });

                    this.eventSource.onmessage = (e) => {
                        console.log("MESSAGE", e);

                        localStorage.setItem("lastEventId", e.lastEventId);
                        this.processMessage(JSON.parse(e.data));
                    };
                }

                if (mercureUrl) {
                    const pushNotificationUrl = new URL(
                        mercureUrl + "/.well-known/mercure?topic=push"
                    );
                    this.pushNotificationSource = new EventSourcePolyfill(
                        pushNotificationUrl.toString(),
                        {
                            headers: {
                                Authorization:
                                    "Bearer " + this.user.mercureToken,
                            },
                            heartbeatTimeout: 1800000,
                        }
                    );
                    logger.debug(
                        "PUSH NOTIFICATION ACTIVE",
                        this.pushNotificationSource
                    );

                    this.pushNotificationSource.onmessage = (e) => {
                        const data = JSON.parse(e.data);
                        const notification = JSON.parse(data.summary);
                        console.log("INCOMING NOTIFiCATION", notification);
                        if (notification.user === this.user.id) {
                            this.processPushNotification(notification);
                        }
                    };
                }

                Sentry.configureScope((scope) => {
                    scope.setUser({ username: this.user.username });
                });

                this.localeService.setLocale(this.user);

                return user;
            })
            .catch((error) => {
                console.error(error);
                this.user = null;
                return undefined;
            });
    }

    getUser = () => this.user;

    getCountry() {
        if (!this.country) {
            const country = getPropertyByPath(this.user, "address.country");

            if (country) {
                this.country = country;
            } else {
                for (let address of getPropertyByPath(
                    this.user,
                    "organization.addresses"
                ) || []) {
                    if (address.country) {
                        this.country = address.country;
                        break;
                    }
                }
            }
        }

        if (!this.country) {
            this.country = "DE";
        }

        return this.country;
    }

    /**
     * @returns {Promise<boolean>}
     * @param roleOrRoles string or array of roles
     */
    hasRole = (roleOrRoles) =>
        new Promise((resolve) => {
            if (!this.user?.roles) {
                resolve(false);
            }

            if (
                !roleOrRoles ||
                !roleOrRoles.length ||
                this.user.roles.includes("ROLE_SUPER_ADMIN") ||
                this.user.roles.includes("ROLE_ADMIN")
            ) {
                resolve(true);
            }

            ("string" === typeof roleOrRoles
                ? [roleOrRoles]
                : roleOrRoles
            ).forEach(
                (role) => this.user.roles.includes(role) && resolve(true)
            );

            resolve(false);
        });
}
