import {bindable, customElement, inject} from 'aurelia-framework';
import * as _ from "lodash";
import {Client} from '../api/client';

@customElement('action-display')
@inject(Client)
export class ActionDisplay
{
    @bindable object;
    @bindable showDelete = false;

    rowActions = [];

    constructor(client) {
        this.client = client;
    }

    bind() {
        this.objectChanged();
    }

    objectChanged() {
        this.rowActions = [];

        _.each(this.object.actions, (action) => {
            if (this.isAllowedAction(action)) {
                this.rowActions.push(action);
            }
        });
    }

    isAllowedAction(action) {
        if (this.showDelete && action.preset === 'delete') {
            return true;
        }

        return action.preset !== 'edit' && action.preset !== 'display' && action.preset !== 'delete' && action.preset !== 'log' && true !== action.bulkOnly;
    }

    context(action, targetEntity)
    {
        const context = _.cloneDeep(targetEntity);

        context.contextObjectRef = {
        };

        return {
            id: targetEntity.id,
            modelId: targetEntity.modelId,
            contextObj: targetEntity
        };
    }
}
