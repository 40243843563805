import {bindable, customElement, inject} from "aurelia-framework";
import {Client} from "../../api/client";
import {DialogService} from 'aurelia-dialog';
import {FlashService} from "../../flash/flash-service";
import {WorkflowService} from "../../workflow/workflow-service";
import {BwaPositionsDialog} from "./bwa-positions-dialog";
import "./bwa-statement.less";

@inject(
    Client,
    DialogService,
    FlashService,
    WorkflowService
)
@customElement('sio-accounting-bwa-statement')
export class BwaStatement {

    client;
    dialogService;
    flash;
    form;

    @bindable() bwa;

    pdfConfig = {
        label: 'PDF-Download',
        buttonClass: 'btn btn-primary'
    }
    excelConfig = {
        label: 'Excel-Download',
        buttonClass: 'btn btn-primary'
    }
    submitConfig = {
        label: 'Anzeigen',
        buttonClass: 'btn btn-primary'
    }

    constructor(client, dialogService, flash, workflowService) {
        this.client = client;
        this.dialogService = dialogService;
        this.flash = flash;
        this.workflowService = workflowService;

        this.submit = this.submit.bind(this);
    }

    submit(type) {
        this.excelConfig.diabled = this.pdfConfig.disabled = this.submitConfig.disabled = true;
        this.data = null;

        return this.workflowService
            .trigger(`accounting/generate-bwa${type ? "-" + type : ""}`, [this.bwa], this.form.formService.getValue())
            .then(data => {
                this.data = data[0].bwaStatement;

                if (data[0].url) {
                    window.location.href = data[0].url;
                }

                return data;
            }).finally(() => {
                this.excelConfig.diabled = this.pdfConfig.disabled = this.submitConfig.disabled = false;
            })
    }

    open(positions) {
        this.dialogService
            .open({
                viewModel: BwaPositionsDialog,
                model: {
                    positions: positions,
                    title: 'BWA-Position',
                    headers: this.data.headers
                }
            })
            .whenClosed(response => {
            });
    }
}
