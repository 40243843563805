import {inject, LogManager} from "aurelia-framework";
import * as _ from "lodash";
import {ActionHandlerInterface} from "./action-handler.interface";
import {DialogView} from "../../view/dialog-view";
import {Confirm} from '../../dialog/confirm';
import {DialogService} from "aurelia-dialog";
import {WorkflowService} from "../../workflow/workflow-service";

const logger = LogManager.getLogger('action-handler');

@inject(
    DialogService,
    WorkflowService,
)
export class WorkflowActionHandler extends ActionHandlerInterface {
    constructor(
        dialog,
        workflowService
    ) {
        super();

        this.dialog = dialog;
        this.workflowService = workflowService;
    }

    getActionType() {
        return 'workflow';
    }

    getReturnContext(config, context) {
        let returnContext = Object.assign(
            {
                workflowId: config.workflowId,
                id: config.id,
                formId: config.formId,
                confirm: config.confirm,
                confirmTitle: config.confirmTitle, // @fixme rename to title
                bindValues: config.bindValues,
                bulk: config.bulk || true,
                label: config.label,
                title: config.title,
                help: config.help,
                embedProperty: config.embedProperty,
            },
            context
        );

        if (context.data) {
            returnContext.data = context.data;
        }

        return returnContext;
    }

    getAction(returnContext) {
        return () => {
            let idParameters;

            if (!returnContext.id) {
                idParameters = null;
            } else {
                idParameters = _.castArray(returnContext.id);
            }

            let data = returnContext.data;

            if (returnContext.formId) {

                return this.dialog.open(
                    {
                        viewModel: DialogView,
                        model: Object.assign(
                            {},
                            returnContext.contextObj || returnContext.data || {}, // allow templating in title
                            {
                                formContext: {
                                    workflowId: returnContext.workflowId,
                                    //TODO Problem with a couple of forms, is the wrong object id here?
                                    id: returnContext.id,
                                    modelId: returnContext.modelId,
                                    contextObjectRef: returnContext.contextObjectRef,
                                    bindValues: returnContext.bindValues,
                                },
                                embedProperty: returnContext.embedProperty,
                                title: returnContext.confirmTitle ?? returnContext.title ?? returnContext.label, // @fixme rename to title
                                submitHandler: this.workflowFormSubmit.bind(this),
                                finishContextHandler: this.finishContext.bind(this),
                                content: '<sio-form config.bind="\'' + returnContext.formId + '\'" ' +
                                    'context-object-ref.bind="params.formContext" ' +
                                    'data.bind="params.formData" ' +
                                    'disable-local-storage.one-time="true" ' +
                                    'params.bind="{bindValues: params.formContext.bindValues, disableSubmitMessage: true}" ' +
                                    'submit.bind="params.submitHandler" ' +
                                    'embed-property.bind="params.embedProperty" ' +
                                    'sio-post-submit.trigger="params.embedProperty != null ? null : params.finishContextHandler(dialogController)"></sio-form>',
                            })
                    }
                ).whenClosed(data => {
                    return data.output ? data.output : data;
                });
            } else if (returnContext.confirm) {

                return this.dialog.open(
                    {
                        viewModel: Confirm,
                        model: {
                            message: returnContext.confirm,
                            title: returnContext.confirmTitle,
                            okMessage: 'sio.execute',
                            cancelMessage: 'sio.cancel',
                            context: {
                                params: returnContext
                            }
                        }
                    }
                ).whenClosed(response => {
                    if (response.wasCancelled) {
                        return;
                    }

                    return this.workflowService.trigger(returnContext.workflowId, idParameters, data).then(
                        async (data) => {
                            // noinspection JSCheckFunctionSignatures
                            logger.debug('Workflow result', data);

                            return data;
                        }
                    );
                });
            }

            return this.workflowService.trigger(returnContext.workflowId, idParameters, data).then(
                async (data) => {
                    // noinspection JSCheckFunctionSignatures
                    logger.debug('Workflow result', data);

                    return data;
                }
            );
        };
    }

    finishContext(dialogController) {
        if (dialogController && this.currentData) {
            dialogController.ok(this.currentData);
        }
        this.currentData = null;
    }

    workflowFormSubmit(value) {
        // "this" is Form object here
        let idParameters = null;
        if (value.context.id) {
            idParameters = _.castArray(value.context.id);
        }

        return this.workflowService.trigger(value.context.workflowId, idParameters, value.object).then(data => {

            //Workflow handles successes as promise fullfilment, form errors are displayed
            if (data.errors && data.code === 400) {
                throw {data: data};
            }

            //Handle exceptions without closing the form
            if (data.localizedMessage && data.code === 400) {
                throw {data: data};
            }

            console.log('Workflow result', data);

            this.currentData = data;

            return data;
        });
    }
}
